//Envia el form
function sendData(data, url) {
  var XHR = new XMLHttpRequest();

  // We define what will happen in case of error
  XHR.addEventListener('error', function (event) {
    console.log('Algo salió mal. No se enviaron los datos. (error)');
  });

  // We setup our request
  XHR.open('POST', url);

  // We just send our FormData object, HTTP headers are set automatically
  XHR.send(data);

  // We define what will happen if the data are successfully sent
  XHR.addEventListener('load', function (event) {
    // console.log(event.target.response);
    if (event.target.response === 'OK.') {
      console.log(event.target.response);
      console.log('Su mensaje fue enviado exitosamente');
      $('.c-form input, .c-form textarea').val('');
      $('#validation').addClass('is-visible').removeClass('c-form__validation_danger').text('Su mensaje fue enviado exitosamente.');
      $('#submit').html('Enviado<i class="icon-ok"></i>').addClass('cta_success');
      setTimeout(function(){
        $('#validation').removeClass('is-visible c-form__validation_danger').text('');
        $('#submit').html('Enviar').removeClass('cta_success');
      }, 5000);
    } else {
      console.log(event.target.response);
      console.log('Algo salió mal. Por favor intenta otra vez. (load)');
      $('#validation').addClass('is-visible').addClass('c-form__validation_danger').text('Algo salió mal. Por favor intenta otra vez.');
    }
  });
}
