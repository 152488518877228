$(document).on('resize', function() {
  navitagionOnScroll();
});

$(document).on('scroll', function() {
  navitagionOnScroll();
});

/* Lleva cualquier #hash de la url a un ID existente en la pagina con una trasicion suave */
function anchorGoToHash() {
  $('a[href*="#"]:not([href="#"])').click(function (e) {
    e.preventDefault();
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      console.log(target);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 500);
        return false;
      }
    }
  });
}

/* nav styles and actions */
function navitagionOnScroll() {
  if ($('.l-hero').length > 0) {
    var st = $(window).scrollTop();
    var ot = $('.l-hero').offset().top + 200;

    /* set multiple styles to nav */
    if (st > ot) {
      $('.c-navigation').addClass('is-dark');
      $('.c-navigation').removeClass('is-transparent');
    } else {
      $('.c-navigation__trigger img').attr('src', 'assets/img/misc/c-navigation__menu_white.svg');
      $('.c-navigation').removeClass('is-dark');
      $('.c-navigation').addClass('is-transparent');
    }
  }
}

/* nav behavior on trigger */
function triggerMenuNavigation(el) {
  var bars = el.find('.c-navigation__bars');

  if (bars.hasClass('is-opened')) {
    bars.removeClass('is-opened');
    $('.c-navigation__menu').hide();
    el.removeClass('is-visible');
    if ( $('.c-navigation').hasClass('is-transparent') ) {
      $('.c-navigation').removeClass('is-dark');
    }
  } else {
    bars.addClass('is-opened');
    $('.c-navigation__menu').show();
    el.addClass('is-visible');
    if ( $('.c-navigation').hasClass('is-transparent') ) {
      $('.c-navigation').removeClass('is-transparent');
      $('.c-navigation').addClass('is-dark');
    }
  }
}

function prefentDefaultFunction(event) {
  event.preventDefault();
}

function enableInputs() {
  $('table input').removeAttr('disabled');
  $('.c-save__datepicker, .c-save__edit').css('display', 'none');
  $('.c-save__button, .c-save__cancel').css('display', 'inline-block');
}

function disableInputs() {
  $('table input').attr('disabled', 'true');
  $('.c-save__datepicker, .c-save__edit').css('display', 'inline-block');
  $('.c-save__button, .c-save__cancel').css('display', 'none');

}

// Email validation
function valemail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function f_field_validation() {
  var isValid = false;

  $('form input[type="text"], form input[type="email"], form textarea').each(function () {
    if ($.trim($(this).val()) === '') {
      $(this).addClass('is-wrong');
    } else {
      $(this).removeClass('is-wrong');
    }
  });

  if (!$('.c-form_main .c-form__input').hasClass('is-wrong')) {
    isValid = true;
  }

  return isValid;
}

function f_contact_validation(event) {
  event.preventDefault();

  var data = new FormData();
  var fields = ['name', 'email', 'message'];

  for (var i = 0; i < fields.length; i++) {
    data.append(fields[i], $("#" + fields[i]).val());
  }
  // console.log(data);

  if ( f_field_validation() ) {
    sendData(data, "./assets/php/phpmailer/sendEmail.php");
  }

}

function f_contactJobs_validation(event) {
  event.preventDefault();

  if ( $('#file').val() !== '' ) {
    $('#submit-job').click();
    $('#validation-job').html('<i class="icon-spin4 animate-spin"></i>').addClass('is-visible');

    $('#response').on('load', function() {
      var response = $(this).contents().find('body').html(),
          input = $(this).parent().find('#file');
      console.log('Submit response: '+response);

      if ( response === 'OK.' ) {
        input.removeClass('is-wrong').val('');
        $('#validation-job').html('Recibimos sus datos. Muchas gracias.').removeClass('c-form__validation_warning');
        $('#submit-trigger').html('Enviado<i class="icon-ok"></i>').addClass('cta_success');
        setTimeout(function(){
          $('#validation-job').removeClass('is-visible c-form__validation_warning').text('');
          $('#submit-trigger').html('Enviar').removeClass('cta_success');
        }, 5000);
        console.log('Enviado');

      } else if ( response === 'Unknown/not permitted file type') {

        $('#validation-job').html('Archivo no permitido.').addClass('c-form__validation_warning');
        input.addClass('is-wrong');

      } else if ( response === 'Unknown/file size not allowed') {

        $('#validation-job').html('Archivo demasiado grande.').addClass('c-form__validation_warning');
        input.addClass('is-wrong');

      } else {

        $('#validation-job').html('Algo salió mal. Por favor intenta otra vez.').addClass('c-form__validation_warning');
        console.log('Error');

      }

    });

  } else {
    $('#file').addClass('is-wrong');
  }
}
