/* ---- Genera un log en la consola indicando que funcion se ejecuta ---- */
function logRunningFunction(fun) {
  // Ex: logRunningFunction(arguments.callee.name);
  console.log('%c\nRunning: '+fun+'();', 'color: #ababab;');
}

/* ---- Genera un log en la consola indicando un comentario ---- */
function logCommentFunction(comment) {
  console.log('%cComment: '+comment+'', 'color: #888;');
}
